import { Link } from "gatsby";
import React, { useState } from "react";
import Headroom from "react-headroom";
import logo from "../images/logo.png";

function Header() {
  const [isExpanded, toggleExpansion] = useState(false);

  return (
    <Headroom className="w-full ">
      <div className=" w-full p-4 mx-auto  bg-black">
        <div className="max-w-6xl mx-auto flex flex-wrap items-center justify-between ">
          <div className="w-3/4 md:max-w-xs ">
            <Link to="/">
              <img className="w-40" src={logo}></img>
            </Link>
          </div>

          <button
            className="items-center block px-3 py-3 text-white border border-white rounded md:hidden"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              width="3rem"
              height="3rem"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          <nav
            className={`${
              isExpanded
                ? `block h-screen text-center pt-24 text-3xl md:text-base md:p-0 md:block md:items-center md:h-auto w-full md:w-auto`
                : `hidden`
            } md:block md:items-center w-full md:w-auto`}
          >
            {[
              {
                route: `/#om`,
                title: `Om Jospers`,
              },
              {
                route: `https://www.bokabord.se/r/jospers-vin-&-grill/616`,
                title: `Boka Bord`,
              },
              {
                route: `/#meny`,
                title: `Meny`,
              },

              {
                route: `/#contact`,
                title: `Besök Oss
                `,
              },
              {
                route: `/#contact`,
                title: `Oppetider
                `,
              },
            ].map((link) => (
              <Link
                className="block mt-4 text-white no-underline md:inline-block md:mt-0 md:ml-6 hover:text-orange-600"
                key={link.title}
                to={link.route}
                onClick={() => toggleExpansion(!isExpanded)}
              >
                {link.title}
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </Headroom>
  );
}

export default Header;
