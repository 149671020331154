import PropTypes from "prop-types";
import React from "react";

import Header from "./header";

function Layout({ children }) {
  return (
    <div>
      <main className=" flex-1 w-full   mx-auto font-lora  ">
        <Header />
        {children}
      </main>
      <footer className="bg-black text-center  flex">
        <nav className=" p-8 m-auto">
          <p className="text-white">2020 Jospers Vin & Grill</p>
        </nav>
      </footer>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
